import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav, Row, Col, Image } from "react-bootstrap"
import "../../styles/textblock.css"

const TextBlock = ({ title, text }) => (
  <div className="textblock-container">
    <h3 className="inlineDisplay">{title}</h3>{" "}
    <label className="textblock-text">{text}</label>
  </div>
)

export default TextBlock
