import React from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Spinner from "react-bootstrap/Spinner"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import Typography from "@material-ui/core/Typography"
import CvUpload from "./steps/CvUpload"
import ApplicationFormStep1 from "./steps/ApplicationFormStep1"
import ApplicationFormStep2 from "./steps/ApplicationFormStep2"
import ContentBlock from "../ContentBlock"

import { ContactService } from "../../../services/ContactService"
import genericTagHelper from "../../../helpers/api/genericTagHelper"

import "../../../styles/application.css"

const { forwardRef, useImperativeHandle } = React

const _newApplicationSentTitle = "Candidature reçue!"
const _newApplicationSentDescription =
  "Merci de votre intérêt envers notre poste. Notre équipe de recrutement vous contactera rapidement pour discuter de votre candidature.<br/>Merci!"
const _offerNotSuitableApplicationSentTitle = "Bien reçue!"
const _offerNotSuitableApplicationSentDescription =
  "Merci de votre intérêt envers nos postes. Dès qu'on a des postes répondant à vos critères, notre équipe de recrutement vous contactera rapidement.<br/>Merci!"

function ApplicationProcessModal(props) {
  const [
    isApplicationCompletedAndSent,
    setIsApplicationCompletedAndSent,
  ] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState(0)
  const [resumeToken, setResumeToken] = React.useState(null)
  const [resumeId, setResumeId] = React.useState(null)
  const [isCVInSubmittingMode, setIsCVInSubmittingMode] = React.useState(false)
  const [isContactConsentChecked, setIsContactConsentChecked] = React.useState(
    false
  )
  const [newApplicationSentTitle, setNewApplicationSentTitle] = React.useState(
    _newApplicationSentTitle
  )
  const [
    newApplicationSentDescription,
    setNewApplicationSentDescription,
  ] = React.useState(_newApplicationSentDescription)
  const [applicationData, setApplicationData] = React.useState({
    gender: "",
    givenName: "",
    familyName: "",
    address: {
      postalCode: "",
      city: "",
      line1: "",
      line2: "",
    },
    email: "",
    phone: "",
    formationTags: [],
    languageTags: [],
    experienceTags: [],
    nonSolicitation: false,
  })

  const steps = getSteps()

  const updateApplicationData = (label, value) => {
    applicationData[label] = value
    setApplicationData(applicationData)
  }

  const submitContactApplication = async () => {
    // check first if contactConsent is checked
    if (isContactConsentChecked) {
      // setting application submitting mode with loading view
      setIsCVInSubmittingMode(true)
      // lets first make a copy of applicationData
      const applicationDataCopy = JSON.parse(JSON.stringify(applicationData))
      // now lets remove useless data
      delete applicationDataCopy.normaLizedFormationTags
      delete applicationDataCopy.normaLizedLanguageTags
      delete applicationDataCopy.normaLizedExperienceTags

      // 1- CASE WHERE THE JOB IS NOT SUITABLE FOR THE CONTACT
      if (
        props.emailinitialvalue !== "" &&
        (props.emailinitialvalue.trim() !== null ||
          props.emailinitialvalue.trim() !== "")
      ) {
        setNewApplicationSentTitle(_offerNotSuitableApplicationSentTitle)
        setNewApplicationSentDescription(
          _offerNotSuitableApplicationSentDescription
        )
      }
      // 2- CASE WHERE THE CONTACT APPLY FOR THE JOB
      else {
        setNewApplicationSentTitle(_newApplicationSentTitle)
        setNewApplicationSentDescription(_newApplicationSentDescription)
      }

      try {
        // submitting contact application
        const result = await ContactService.sendContactApplication(
          props.jobid,
          resumeToken,
          applicationDataCopy
        )
        setIsApplicationCompletedAndSent(true)
        setIsCVInSubmittingMode(false)
      } catch (error) {
        setIsCVInSubmittingMode(false)
        setTimeout(() => {
          alert(
            "Oups! une erreur inconnue s'est produite lors de l'envoi. Veuillez réessayer SVP! Si l'erreur persiste, veuillez nous contacter. Merci"
          )
        }, 500)
      }
    } else {
      alert("Veuillez svp cocher la case de consentement d'habord")
    }
  }

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      submitContactApplication()
    } else if (activeStep === 0) {
      setIsCVInSubmittingMode(false)
      setActiveStep(activeStep + 1)
    } else setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
    setIsCVInSubmittingMode(false)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const handleResumeId = async resumeId => {
    if (!resumeId) {
      throw new TypeError("resumeId missing...")
      alert("resumeId missing... please contact the administrator")
    }
    setResumeId(resumeId)
  }

  const goToStep2 = async () => {
    // gettting resumeInfos
    const resumeInfos = await ContactService.getContactResume(
      props.jobid,
      resumeId
    )
    if (resumeInfos) {
      setResumeToken(resumeInfos.resumeToken)
      normalizeResumeData(resumeInfos)
      handleNext()
    }
  }

  const normalizeResumeData = resumeInfos => {
    const _formationTags = resumeInfos.formationTags
      ? resumeInfos.formationTags
      : {}
    const _languageTags = resumeInfos.languageTags
      ? resumeInfos.languageTags
      : {}
    const _experienceTags = resumeInfos.experienceTags
      ? resumeInfos.experienceTags
      : {}
    const address = resumeInfos.address ? resumeInfos.address : {}
    if (address.postalCode === undefined) address.postalCode = ""
    if (address.city === undefined) address.city = ""
    if (address.line1 === undefined) address.line1 = ""
    if (address.line2 === undefined) address.line2 = ""

    const normaLizedFormationTags =
      _formationTags.constructor === Object &&
      Object.keys(_formationTags).length === 0
        ? []
        : genericTagHelper.getNormalizedTagList(
            "FORMATION_TAGS",
            _formationTags
          )
    const normaLizedLanguageTags =
      _languageTags.constructor === Object &&
      Object.keys(_languageTags).length === 0
        ? []
        : genericTagHelper.getNormalizedTagList("LANGUAGE_TAGS", _languageTags)
    const normaLizedExperienceTags =
      _experienceTags.constructor === Object &&
      Object.keys(_experienceTags).length === 0
        ? []
        : genericTagHelper.getNormalizedTagList(
            "EXPERIENCE_TAGS",
            _experienceTags
          )

    const formationTags = genericTagHelper.getBuildTagList(
      normaLizedFormationTags
    )
    const languageTags = genericTagHelper.getBuildTagList(
      normaLizedLanguageTags
    )
    const experienceTags = genericTagHelper.getBuildTagList(
      normaLizedExperienceTags
    )

    const normalizeResumeData = {
      gender: resumeInfos.gender ? resumeInfos.gender : "",
      givenName: resumeInfos.givenName ? resumeInfos.givenName : "",
      familyName: resumeInfos.familyName ? resumeInfos.familyName : "",
      address,
      email: resumeInfos.email ? resumeInfos.email : "",
      phone: resumeInfos.phone ? resumeInfos.phone : "",
      formationTags,
      languageTags,
      experienceTags,
      normaLizedFormationTags,
      normaLizedLanguageTags,
      normaLizedExperienceTags,
      nonSolicitation: resumeInfos.nonSolicitation
        ? resumeInfos.nonSolicitation
        : false,
    }

    if (normalizeResumeData.email === "" && props.emailinitialvalue !== null) {
      normalizeResumeData.email = props.emailinitialvalue
    }

    setApplicationData(normalizeResumeData)
  }

  const setContactConsent = value => {
    setIsContactConsentChecked(value)
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
    >
      <Modal.Body>
        {!isApplicationCompletedAndSent ? (
          <div className="">
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {activeStep === steps.length ? (
                <div>
                  <Typography className="">All steps completed</Typography>
                  <Button onClick={handleReset}>Reset</Button>
                </div>
              ) : (
                <div>
                  <StepContent
                    activeStep={activeStep}
                    handleResumeId={handleResumeId}
                    isCVInSubmittingMode={isCVInSubmittingMode}
                    isContactConsentChecked={isContactConsentChecked}
                    setIsCVInSubmittingMode={setIsCVInSubmittingMode}
                    updateApplicationData={updateApplicationData}
                    goToStep2={goToStep2}
                    applicationData={applicationData}
                    setContactConsent={setContactConsent}
                    jobId={props.jobid}
                  />
                  {activeStep === 0 || isCVInSubmittingMode ? null : (
                    <div className="application-action-btns">
                      <Button
                        disabled={activeStep === 0}
                        variant="contained"
                        color="primary"
                        onClick={handleBack}
                        className=""
                      >
                        Retour
                      </Button>
                      <Button onClick={handleNext}>
                        {activeStep === steps.length - 1
                          ? "Envoyer"
                          : "Poursuivre"}
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <ContentBlock
            title={newApplicationSentTitle}
            description={newApplicationSentDescription}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}

function getSteps() {
  return [
    "Téléverser votre CV",
    "Informations personnelles",
    "Formations & Expériences",
  ]
}

function StepContent(props) {
  switch (props.activeStep) {
    case 0:
      return !props.isCVInSubmittingMode ? (
        <CvUpload
          jobId={props.jobId}
          setIsCVInSubmittingMode={props.setIsCVInSubmittingMode}
          handleResumeId={props.handleResumeId}
          goToStep2={props.goToStep2}
        />
      ) : (
        <div className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
          <span>Ça serait pas long...</span>
        </div>
      )
    case 1:
      return (
        <ApplicationFormStep1
          setApplicationData={props.updateApplicationData}
          applicationData={props.applicationData}
        />
      )
    case 2:
      return (
        <div>
          <div className={props.isCVInSubmittingMode ? "displayNone" : ""}>
            <ApplicationFormStep2
              setApplicationData={props.updateApplicationData}
              applicationData={props.applicationData}
              isContactConsentChecked={props.isContactConsentChecked}
              setContactConsent={props.setContactConsent}
            />
          </div>
          <div
            className={
              props.isCVInSubmittingMode
                ? "spinnerContainer"
                : "spinnerContainer displayNone"
            }
          >
            <Spinner animation="border" variant="primary" />
            <span>Ça serait pas long...</span>
          </div>
        </div>
      )
    default:
      return "Unknown stepIndex"
  }
}

const Application = forwardRef((props, ref) => {
  const [offerApplicationModalShow, setOfferApplicationModal] = React.useState(
    false
  )

  useImperativeHandle(ref, () => ({
    handleOfferApplicationVisibility(isVisible) {
      setOfferApplicationModal(isVisible)
    },
  }))

  return (
    <ApplicationProcessModal
      show={offerApplicationModalShow}
      onHide={() => setOfferApplicationModal(false)}
      jobid={props.jobId}
      emailinitialvalue={props.email}
    />
  )
})

export default Application
