import React from "react"
import PropTypes from "prop-types"
import PageFooter from "../PageFooter"
import "../../../styles/app.css"
import "../../../styles/layout.css"
import "bootstrap/dist/css/bootstrap.min.css"
import clientData from "../../../client/data/data.json"

const ApplicationLayout = ({ children }) => {
  return (
    <>
      <div className="layout-container">{children}</div>
    </>
  )
}

ApplicationLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ApplicationLayout
