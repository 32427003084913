import React from "react"
import PropTypes from "prop-types"
import "../../styles/contentblock.css"

const makeItBulletedList = text => {
  text = text.replace(/;\s*$/, "")
  const elmts = text.split(";")
  if (elmts.length === 1) return text

  const bulletedList = elmts.map((elmt, index) => {
    return (
      <li className="list" key={index}>
        {elmt}
      </li>
    )
  })

  return <ul className="">{bulletedList}</ul>
}

function ContentBlockBulletedList(props) {
  return (
    <div className="contentblock-container">
      {props.title ? (
        <h3
          className=""
          style={{
            fontSize: props.titleSize,
            fontWeight: props.titleFontWeight,
          }}
        >
          {props.title}
        </h3>
      ) : null}
      {props.description ? makeItBulletedList(props.description) : null}
    </div>
  )
}

ContentBlockBulletedList.propTypes = {
  titleSize: PropTypes.string,
  titleFontWeight: PropTypes.string,
}

ContentBlockBulletedList.defaultProps = {
  titleSize: "1.75rem",
  titleFontWeight: `bold`,
}

export default ContentBlockBulletedList
