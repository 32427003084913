import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import Dropzone from 'react-dropzone-uploader'
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ApplicationLayout from "../ApplicationLayout"
import {ContactService} from "../../../../services/ContactService"

import "../../../../styles/app.css"
import "../../../../styles/cvUpload.css"
import 'react-dropzone-uploader/dist/styles.css'

const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const text = 'Choose file';
    if(files.length === 1) return null;
    return (
        <div className="uploader">
            <p>
                Merci de téléverser ou <i>Drag&Drop</i> votre CV afin de débuter le processus de candidature
            </p>
            <label className="textAlignCenter">
                <CloudUploadIcon className="cloud-up-icon" style={{ fontSize: 50 }}/>
                <input
                    style={{ display: 'none' }}
                    type="file"
                    accept={accept}
                    multiple
                    onChange={e => {
                        getFilesFromEvent(e).then(chosenFiles => {
                            onFiles(chosenFiles)
                        })
                    }}
                />
            </label>
        </div>
    )
}

const CustomInput = (props) => {
    const handleGetUploadParams = async ({ file, meta: { name } }) => {
        // getting new contact resume info
        const { resumeId, url } = await ContactService.getNewContactResume(props.jobId);
        props.handleResumeId(resumeId);
        const urlUp = 'https://cors-anywhere.herokuapp.com/' + url;
        return { method: "PUT", body: file , url : urlUp }
    }

    const handleSubmit = async (files, allFiles) => {
        allFiles.forEach(f => f.remove());
        props.setIsCVInSubmittingMode(true);
        props.goToStep2();
    }
    
    const getFilesFromEvent = e => {
        return new Promise(resolve => {
            getDroppedOrSelectedFiles(e).then(chosenFiles => {
                resolve(chosenFiles.map(f => f.fileObject))
            })
        })
    }
    
    return (
        <Dropzone
            accept="image/*,.pdf, .docx,.doc"
            getUploadParams={handleGetUploadParams}
            onSubmit={handleSubmit}
            InputComponent={Input}
            getFilesFromEvent={getFilesFromEvent}
        />
    )
}

const CvUpload = (props) => (
    <ApplicationLayout>
        <Container className="offer-application-container cv-upload-container" fluid>
            <Row>
                <Col sm={12}>
                    <CustomInput jobId = {props.jobId} setIsCVInSubmittingMode = {props.setIsCVInSubmittingMode} handleResumeId={props.handleResumeId} goToStep2={props.goToStep2}/>
                </Col>
            </Row>
        </Container>
    </ApplicationLayout>
)

CvUpload.propTypes = {

}

CvUpload.defaultProps = {

}

export default CvUpload
