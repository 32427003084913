import PropTypes from "prop-types"
import React from "react"
import '../../styles/contentblock.css';

function ContentBlock (props){
  if(props.isDescriptionRequiered && !props.description && !props.contactViaLinkedin) return null
  return (
    <div className="contentblock-container">
        {props.title ? <h3 className="" style={{ fontSize: props.titleSize, fontWeight: props.titleFontWeight}}>{props.title}</h3> : null}
        {!props.subtitle ? null : <label className="" style={{ fontSize: props.subtitleSize}}>{props.subtitle}</label>}
        {props.description ? <p className="" dangerouslySetInnerHTML={{ __html: props.description }}></p> : null}
        {props.contactViaLinkedin ? <a href={props.contactViaLinkedin} target="_blank" rel="noopener"><img src="/icons/linkedin-contact-via.jpg" alt="linkedin" width="150" height="50" /></a> : null}
    </div>
  )
} 

ContentBlock.propTypes = {
  titleSize: PropTypes.string,
  subtitleSize: PropTypes.string,
  titleFontWeight: PropTypes.string,
  isDescriptionRequiered: PropTypes.bool,
}

ContentBlock.defaultProps = {
  siteTitle: '1.75rem',
  subtitleSize: `12px`,
  titleFontWeight: `bold`,
  isDescriptionRequiered: true
}

export default ContentBlock
