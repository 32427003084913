import React from "react"
import SEO from "../../seo"
import BaseLayout from "../BaseLayout"
import HeaderImage from "../HeaderImage"
import TextBlock from "../TextBlock"
import ContentBlock from "../ContentBlock"
import ContentBlockBulletedList from "../ContentBlockBulletedList"
import {
  Container,
  Row,
  Col,
  ResponsiveEmbed,
  Image,
  Form,
  Button,
} from "react-bootstrap"
import Application from "../offerApplication/index"

import "./offerDetail.scss"

const { useRef } = React

const offerNotSuitableForTheContact_OfferId = 7720

function OfferFooterBlock(props) {
  const handleEmailClick = event => {
    event.preventDefault()
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (emailRegex.test(props.emailValue)) {
      props.applicationRef.current.handleOfferApplicationVisibility(true)
    } else {
      alert("Veuillez entrer une adresse courriel valide")
    }
  }

  return (
    <>
      <Col sm={5} className="footer-img-container">
        <Image src={props.offerFooterImgSrc} />
      </Col>
      <Col sm={7}>
        <TextBlock title="Ce poste ne vous convient pas?" />
        <div className="courrier">
          <Form onSubmit={handleEmailClick}>
            <Form.Row className="align-items-center">
              <Col sm={9}>
                <Form.Control
                  className="mb-2"
                  id="inlineFormInput"
                  placeholder="Adresse courriel personnelle"
                  value={props.emailValue}
                  onChange={e => {
                    props.setEmailValue(e.target.value.trim())
                  }}
                />
              </Col>
              <Col sm={3}>
                <Button
                  type="submit"
                  className="mb-2"
                  onClick={handleEmailClick}
                >
                  Valider
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </div>
      </Col>
    </>
  )
}

function OfferDetail({ pageContext: { offer } }) {
  const offerHeaderImgSrc = null
  const offerFooterImgSrc =
    "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22118%22%20height%3D%22250%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20118%20250%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1730634ab5e%20text%20%7B%20fill%3Argba(255%2C255%2C255%2C.75)%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A13pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1730634ab5e%22%3E%3Crect%20width%3D%22118%22%20height%3D%22250%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2226.390625%22%20y%3D%22131%22%3E118x250%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
  const videoLinkExist = true
  const [emailValue, setEmailValue] = React.useState("")
  const childRef = useRef()
  if (!offer) return <></>
  return (
    <BaseLayout>
      <SEO title="Offer" />
      <Container className="offer-detail-container" fluid>
        {offerHeaderImgSrc ? (
          <Row>
            {" "}
            <Col sm={12}>
              {" "}
              <HeaderImage headerImageSrc={offerHeaderImgSrc} />{" "}
            </Col>{" "}
          </Row>
        ) : null}
        <Row>
          <Col sm={12} style={{ padding: "0" }}>
            <div
              style={{
                width: "100%",
                backgroundColor: "#b1cd0a",
                padding: "0.6% 10%",
              }}
            >
              <h1
                style={{ fontSize: "32px", margin: "0" }}
              >{`${offer.titreOffre} - ${offer.nombrePostes} poste(s) disponible(s)`}</h1>
            </div>
            {/* <TextBlock
                title={offer.titreOffre}
                text={" - " + offer.nombrePostes + " poste(s) disponible(s)"}
                /> */}
          </Col>
        </Row>
        <Row className="offer-detail-body">
          <Col sm={1}></Col>
          <Col sm={7}>
            <ContentBlock
              title="Description du poste"
              description={offer.descriptionPoste}
            />
            <ContentBlockBulletedList
              title="Exemples de tâches à réaliser"
              description={offer.exempleTachesARealiser}
            />
            <ContentBlock
              title="Compétences recherchées"
              description={offer.competencesRequises}
            />
            <ContentBlock
              title="Formation requise"
              subtitle={
                offer.equivalenceObligatoire === 1
                  ? "Les diplômes provenant de l'extérieur du Québec, doivent obligatoirement avoir obtenus un équivalence du Ministère."
                  : false
              }
              description={offer.formationRequise}
            />
            <ContentBlock
              title="On dit de vous que vous êtes...."
              description={offer.caracteristiquesPersonnelles}
            />
            <ContentBlockBulletedList
              title="Conditions d'emploi"
              description={offer.salaire + ";" + offer.autresConditions}
            />
          </Col>
          <Col sm={4}>
            {videoLinkExist ? (
              <div style={{ width: "auto", height: "auto", padding: "15px" }}>
                <ResponsiveEmbed aspectRatio="16by9">
                  <embed src="https://www.youtube.com/embed/-jGL8wNnU1k" />
                </ResponsiveEmbed>
              </div>
            ) : null}
            <ContentBlock
              title="Notre Client"
              description={offer.nomEmployeur}
            />
            <ContentBlock
              title="Environnement de travail"
              description={offer.environnementTravailEmployeur}
            />
            {offer.adresseMapEmployeur ? (
              <div style={{ width: "auto", height: "auto", padding: "15px" }}>
                <ResponsiveEmbed aspectRatio="16by9">
                  <embed src={offer.adresseMapEmployeur} />
                </ResponsiveEmbed>
              </div>
            ) : null}
            <ContentBlock
              title="Personne Contact"
              contactViaLinkedin={offer.idUtilisateur.urlLinkedin}
            />
            <br />
            {/* setting the correct jobId whether the job is suitable for the contact or not*/}
            <Application
              jobId={
                emailValue !== ""
                  ? offerNotSuitableForTheContact_OfferId
                  : offer.tags
              }
              email={emailValue}
              ref={childRef}
            />
            <div className="padding-15px apply-for-container">
              <span
                className="postulationCTA"
                size="lg"
                onClick={() =>
                  childRef.current.handleOfferApplicationVisibility(true)
                }
                block
              >
                Postuler sur cet emploi
              </span>
            </div>
          </Col>
        </Row>
        <div
          style={{ width: "100%", height: "1em", backgroundColor: "#ffffff" }}
        />
        <Row>
          <OfferFooterBlock
            emailValue={emailValue}
            offerFooterImgSrc={offerFooterImgSrc}
            setEmailValue={setEmailValue}
            applicationRef={childRef}
          />
        </Row>
      </Container>
    </BaseLayout>
  )
}

export default OfferDetail
